.ourServices {
	font-weight: 600;
	font-size: 3rem; /* Use rem units for better scalability */
	color: #2a2438;
	text-transform: uppercase;
	margin-top: 20px;
	font-family: "Roboto", sans-serif;
	text-align: center;
	@media screen and (max-width: 768px) {
		font-size: 3.8rem; /* Adjust font size for smaller screens */
	}
}

.service-card-wrapper {
	perspective: 1000px;
	width: 22%; /* Adjusted width for 4 cards per row */
	height: 35vh !important; /* Increased height */
	margin: 20px;
	box-sizing: border-box;
}

.service-card {
	position: relative;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: transform 0.8s;
	background: linear-gradient(-45deg, #6a1a8a, #5c5470, #352f44, #3d0043);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	border-radius: 20px !important; /* Updated border-radius to 20px */
}

.service-card-inner {
	position: relative;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: transform 0.8s;
}

.service-card:hover .service-card-inner {
	transform: rotateY(180deg);
}

.service-card-front,
.service-card-back {
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-radius: 20px; /* Updated border-radius to 20px */
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	background-color: transparent;
	padding: 20px;
	box-sizing: border-box;
	text-align: center;
}

.service-card-front {
	transform: rotateY(0deg);
	color: #2a2438;
}

.service-icon i {
	font-size: 64px; /* Increased icon size */
	color: #ffffff;
	margin-bottom: 40px; /* Increased space between icon and text */
}

.service-card-front h3 {
	font-family: "Roboto", sans-serif;
	color: #ffffff;
	font-size: 28px; /* Increased font size */
	margin-top: 20px; /* Added top margin to create spacing above the text */
}

.service-card-back {
	transform: rotateY(180deg);
	color: #ffffff;
	font-family: "Roboto", sans-serif;
	text-align: center;
}

.service-description {
	font-size: 18px;
	text-align: center;

	@media screen and (max-width: 1024px) {
		font-size: 16px;
	}
}

@media screen and (max-width: 768px) {
	.service-card-wrapper {
		width: 45%; /* Adjust width to accommodate smaller screens */
		height: 450px; /* Adjusted height for smaller screens */
	}

	.service-icon i {
		font-size: 36px;
	}

	.service-card-front h3 {
		font-size: 20px;
	}
}

@media screen and (max-width: 768px) {
	.service-card-wrapper {
		width: 90%; /* Single column on mobile */
		height: 400px; /* Adjusted height for mobile */
		margin: 10px 0; /* Reduce margin on mobile */
	}
}

/* Gradient Animation */
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
