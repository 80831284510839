.map {
	margin: 0;
	padding: 0;
	border-radius: 10px; /* Adds 20px border radius */
	overflow: hidden; /* Ensures the map content stays within the rounded border */
}

.map iframe {
	width: 100%;
	height: 100%;
	border-radius: 10px; /* Adds 20px border radius to the iframe */
	display: block; /* Ensures the iframe respects the parent container's dimensions */
}

.mapNotFound {
	width: 100%;
	text-align: center;
	margin-top: 20px;
}

.mapNotFound img {
	height: 160px;
}
