.hero {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 75vh;
	background: linear-gradient(-45deg, #6a1a8a, #5c5470, #352f44, #3d0043);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;

	@media screen and (min-width: 1269px) {
		flex-direction: row;
	}
	@media screen and (max-width: 1269px) {
		height: 107vh;
	}
	@media screen and (max-width: 769px) {
		margin-bottom: 12%;
		margin-top: 9%;
	}
	@media screen and (max-width: 469px) {
		margin-top: 14%;
	}
	@media screen and (max-width: 339px) {
		margin-top: 20.5%;
	}
}

.hero__content {
	text-align: center;
	width: 100%;
	padding-right: 5%;
	padding-left: 5%;
	@media screen and (max-width: 769px) {
		width: 75%;
	}
}

.hero-contact {
	align-items: center;
	margin-top: 6%;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	@media screen and (max-width: 769px) {
		width: 90%;
		margin-top: 2%;
		margin-right: 0%;
	}
	@media screen and (min-width: 1269px) {
		margin-right: 10%;
	}
}

.hero__title,
.hero__subtitle {
	margin: 0;
	color: white;
}

.hero__title {
	font-size: 64px;
	font-weight: lighter;
	@media screen and (max-width: 1269px) {
		padding-top: 6%;
	}
	@media screen and (max-width: 769px) {
	}
}

.hero__subtitle {
	font-size: 40px;
	font-weight: lighter;
}

.hero__button {
	display: inline-block;
	outline: none;
	border: none;
	cursor: pointer;
	background: #1d1b1b;
	color: #ffffff;
	border-radius: 8px;
	padding: 14px 24px 16px;
	font-size: 18px;
	font-weight: 700;
	line-height: 1;
	border: 3px solid white;
	margin-top: 5rem;
	font-family: "Fjalla One", sans-serif;
	transition: background-color 0.3s;
	@media screen and (max-width: 769px) {
		padding: 14px 24px 16px;
		width: 100%;
		margin-top: 4rem;
	}
}

.hero__button:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 1269px) {
	.hero__title,
	.hero__subtitle {
		font-size: 20px;
	}
}

@media screen and (max-width: 769px) {
	.hero__title {
		font-size: 24px;
		padding-bottom: 10px;
	}
	.hero__subtitle {
		font-size: 16px;
	}
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.form-control {
	padding: 7px;
	border-radius: 6px;
	font-size: 16px;
	background: #fbfbfb;
	border: 2px solid transparent;
	height: 36px;
	box-shadow: 0 0 0 1px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 7%),
		0 1px 1.5px 0 rgb(0 0 0 / 5%);
	:focus {
		border: 2px solid #000;
		border-radius: 4px;
	}
}

.form-input {
	width: 88%;
}

.form-hero {
	width: 600px;
	margin: auto;
	padding: 3rem 2rem;
	background: #ffffff;
	border-radius: 10px;
	padding: 2rem;
	margin-bottom: 5%;

	#sendmessage {
		color: #007bff;
		border: 1px solid #007bff;
		display: none;
		text-align: center;
		padding: 15px;
		font-weight: 600;
		margin-bottom: 15px;
	}
	#errormessage {
		color: red;
		display: none;
		border: 1px solid red;
		text-align: center;
		padding: 15px;
		font-weight: 600;
		margin-bottom: 15px;
	}
	#sendmessage,
	#errormessage {
		&.show {
			display: block;
		}
	}
}
.form-group1 {
	display: flex;
	justify-content: space-evenly;
}
.hero-input-fields {
	padding-left: 5%;
}
.form-group2 {
	display: flex;
	justify-content: space-evenly;
	padding-bottom: 16px;
	padding-top: 2%;
}
.form-group3 {
	padding-left: 9%;
	padding-bottom: 5%;
}
.form-group4-parent {
	margin-left: 32px;
}
.form-group4 {
	padding-left: 16px;
	padding-bottom: 16px;
	align-items: center;
	background-color: #352f44;
	border-radius: 8px;
	border-style: none;
	box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	font-family: "RM Neue", sans-serif;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	padding: 10px 21px;
	text-align: center;
	text-transform: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

.button-22:active {
	background-color: #006ae8;
}

.button-22:hover {
	background-color: #1c84ff;
}

.danny {
	margin: 0 auto;
	padding-bottom: 8px;
}
.contact-form-header {
	font-size: 24px;
	padding-left: 36px;
	padding-bottom: 36px;
	@media screen and (max-width: 769px) {
		font-size: 16px;
		font-weight: bold;
	}
}
.disclaimer {
	padding-top: 8px;
	font-size: 12px;
}
