.notFound{
    height: calc(100vh - 103px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgb(249, 246, 246);
}
.notFoundContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1050px;
}

.notFoundTitle{
    font-size: 3rem;
    text-shadow: 0 0 5px black;
    color: var(--text-white);
}
.notFoundContainer img{
    max-width: 400px;
}

@media screen and (max-width:800px){
   
.notFoundTitle{
    font-size: 1.5rem;
    text-shadow: 0 0 5px black;
    color: var(--text-white);
} 
.notFoundContainer img{
    max-width: 300px;
}
}


@media screen and (max-width:430px){
   
    .notFoundTitle{
        font-size: 1rem;
        text-shadow: 0 0 5px black;
        color: var(--text-white);
    } 
    .notFoundContainer img{
        max-width: 200px;
    }
    }