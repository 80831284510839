.topbutton {
	position: fixed;
	bottom: 20px;
	right: 20px;
	padding: 10px 15px;
	border: none;
	background-color: #5c5470;
	color: var(--nav-bg);
	font-size: 16px;
	cursor: pointer;
	border-radius: 5px;
	outline: none;
	@media screen and (max-width: 768px) {
		right: 7px;
	}
}

.topbutton:hover {
	background-color: var(--nav-bg);
}
