@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");

.home {
	position: relative;
	height: calc(100dvh - 70px);
	width: 100%;
}

.landingMobileMode {
	display: none;
}

.ourServices {
	font-weight: 600;
	font-size: 36px;
	color: #2a2438;
	text-transform: uppercase;
	margin-top: 20px;
	font-family: "Fjalla One", sans-serif;
}
@media screen and (max-width: 769px) {
	.sliderDesktopMode {
	}
	.landingMobileMode {
		display: block;
		/* check */
	}
	.leadcapture {
		display: none;
	}
}
