@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");

.ourServices {
	font-weight: 600;
	font-size: 36px;
	color: #2a2438;
	text-transform: uppercase;
	margin-top: 20px;
	font-family: "Fjalla One", sans-serif;
}

/* Ensure the row class uses flexbox to align items properly */
.row {
	display: flex;
	flex-wrap: wrap;
	justify-content: center; /* Center the testimonials horizontally */
	margin: 0 auto;
}

/* Style for each testimonial card */
.testimonial-card {
	margin: 5px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
	border-radius: 10px; /* Rounded corners */
	overflow: hidden; /* Prevents content from overflowing the card's bounds */
	text-align: center;
	flex: 1 1 calc(33.333% - 40px) !important; /* Flex-grow, flex-shrink, and flex-basis to create a responsive grid */
	max-width: calc(
		30.333% - 40px
	) !important; /* Max-width to ensure proper spacing between items */
	transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px;
}

/* Hover effect for testimonial cards */
.testimonial-card:hover {
	transform: translateY(-5px); /* Slight lift on hover */
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

/* Ensure icons are centered and sized appropriately */
.testimonial-icon {
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center; /* Center vertically and horizontally */
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: #f5f5f5; /* Add a background color for contrast */
	font-size: 50px; /* Increase icon size */
	color: #2a2438; /* Icon color */
	border: 4px solid #2a2438; /* Add a border around the icon */
}

/* Center the icon inside the testimonial-icon div */
.testimonial-icon i {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

/* Typography for name and text */
.testimonial-name {
	color: #2a2438;
	font-family: "Fjalla One", sans-serif;
	font-size: 22px; /* Slightly increase font size */
	margin-top: 10px;
}

.testimonial-text {
	color: #5c5470;
	font-family: "Roboto", sans-serif;
	font-size: 16px; /* Set a consistent font size */
	padding: 0 15px; /* Add padding for better text alignment */
	font-weight: 500 !important;
}

/* Adjust for smaller screens */
@media screen and (max-width: 768px) {
	.row {
		flex-direction: column; /* Stack all cards vertically */
		align-items: center; /* Center cards horizontally */
	}

	.testimonial-card {
		flex: 1 1 100%;
		width: 90vw; /* Take up most of the screen width */
		margin: 10px 0; /* Add vertical spacing */
		max-width: 90% !important; /* Adjust max-width for smaller screens */
	}

	.testimonial-icon {
		width: 80px;
		height: 80px;
		font-size: 40px; /* Reduce icon size on smaller screens */
	}

	.testimonial-name {
		font-size: 20px;
	} /* Adjust name font size for smaller screens */

	.testimonial-text {
		font-size: 14px;
		padding: 0 10px; /* Adjust padding for smaller screens */
	}
}

.cardc0ntent {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
	box-shadow: 0px 0px 0px 0px !important;
}
