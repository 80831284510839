.wide-card {
	width: 90%;
	margin: 20px auto;
	margin-top: 15vh !important;
}

.wide-card-content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
	box-shadow: 0px 0px 0px 0px !important;
}

.card-inner {
	display: flex;
	width: 100%;
	align-items: center; /* Center items vertically */
}

.card-img-aboutus {
	width: 50%;
	height: auto; /* Maintain aspect ratio */
	display: block;
	margin: 0 auto; /* Center image horizontally */
	box-shadow: 0px 0px 0px 0px !important;
}

.card-content {
	width: 50%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center; /* Center text vertically */
}

.card-title {
	color: #2a2438 !important;
	font-family: "Fjalla One", sans-serif !important;
	font-weight: bold !important;
}

.card-description {
	color: #5c5470 !important;
}
.title-bottom {
	margin-top: 20px !important;
}

@media screen and (max-width: 768px) {
	.card-inner {
		flex-direction: column;
	}

	.card-img-aboutus,
	.card-content {
		width: 100%;
	}
}
.img-responsive {
	width: 95%;
	height: auto;
	border-radius: 20px;
}
