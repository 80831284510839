@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");

.terms-container {
	margin-top: 5%;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100% !important;
	margin-bottom: 8%;
	@media screen and (max-width: 1268px) {
		margin-top: 10%;
	}
	@media screen and (max-width: 468px) {
		margin-top: 25%;
	}
}

.terms-paper {
	padding: 20px;

	width: 100%;
}

.terms-title {
	color: #2a2438;
	font-family: "Fjalla One", sans-serif;
	margin-bottom: 20px;
	margin-top: 50px !important;
}
.terms-title-first {
	color: #2a2438;
	font-family: "Fjalla One", sans-serif;
	margin-bottom: 20px;
	margin-top: 10px !important;
}
.terms-content {
	color: #5c5470;
	font-family: "Arial", sans-serif;
	margin-bottom: 20px;
}
