@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");

.ourFeatures {
	font-weight: 600;
	font-size: 36px;
	color: #2a2438;
	text-transform: uppercase;
	margin-top: 20px;
	font-family: "Fjalla One", sans-serif;
	text-align: center;
}

.feature-card-wrapper {
	width: 22%;
	margin: 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.feature-card {
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: transform 0.8s;
	box-shadow: none;
	text-align: center;
	border-radius: 10px;
	padding: 10px; /* Added padding inside the card for spacing */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.feature-image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px; /* Increase space between image and title */
	flex-grow: 1; /* Ensures the image container takes up available space */
}

.feature-image {
	width: 200px;
	height: auto;
	border-radius: 8px;
	object-fit: cover;
}

.feature-title {
	color: #2a2438;
	font-family: "Fjalla One", sans-serif;
	font-size: 24px;
	margin-top: 25px !important; /* Spacing between the image and the title */
	margin-bottom: 15px; /* Spacing between the title and description */
}

.feature-description {
	color: #5c5470;
	font-family: "Roboto", sans-serif;
	font-size: 18px;
	text-align: center;
	font-weight: 500 !important;
}

@media screen and (max-width: 1024px) {
	.feature-card-wrapper {
		width: 45%;
	}

	.feature-title {
		font-size: 20px;
	}

	.feature-description {
		font-size: 16px;
	}
}

@media screen and (max-width: 768px) {
	.feature-card-wrapper {
		width: 90%;
		margin: 10px 0;
	}

	.feature-title {
		font-size: 18px;
	}

	.feature-description {
		font-size: 14px;
	}
}
