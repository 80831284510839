@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");

.card {
}

.card-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
}
.card-title {
	color: #2a2438 !important;
	font-family: "Fjalla One", sans-serif !important;
}

.contact-button {
	background-color: #5c5470 !important;
	color: #dbd8e3 !important;
}

.card-description {
	color: #5c5470 !important;
}

.card-img-wrapper {
	display: flex;
	width: 100%;
	align-items: center;
	margin-bottom: 20px;
	justify-content: space-evenly;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: center;
	}
}

.card-img-wrapper.image-right {
	flex-direction: row-reverse;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: center;
	}
}

.card-img {
	height: fit-content;
	margin-top: 6vw;
	width: 34vw;
	@media screen and (max-width: 768px) {
		width: 80%;
	}
}

.card {
	width: 38vw;
	margin-bottom: 20px;
	height: fit-content;
	margin-top: 8rem;
	@media screen and (max-width: 768px) {
		width: 80%;
	}
}

.card-img-wrapper:not(.image-right) {
	@media screen and (max-width: 768px) {
		margin-right: 0px;
	}
}

.card-img-wrapper.image-right {
	@media screen and (max-width: 768px) {
		margin-left: 0px;
	}
}
