@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");

:root {
	--nav-bg: #2a2438;
	--text-gray: #393e46;
	--text-yellow: #fdbe34;
	--text-black: #000000;
	--text-white: #ffffff;
	--text-eight: #ffffff80;
	--text-b: #ffffffb3;
	--text-e: #ffffffe6;
	--text-bc: #00000cc;
	--footer-bg: #333333;
	--pagesHeight: calc(100vh - 70px);
	--pagesWidth: 100%;
	--text-blackC: #000000cc;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-decoration: none;
	list-style-type: none;
}

body {
	color: var(--text-black);
	font-family: "Fjalla One", sans-serif;
	position: relative;
	overflow-x: hidden;
	background-color: var(--text-eight);
	scroll-behavior: smooth;
}

input {
	border-radius: 5px;
}
textarea {
	border-radius: 5px;
}
button {
	border-radius: 5px;
	/* s */
}
