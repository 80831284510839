.lead-capture {
	display: none;
	align-items: center;
	margin-top: 6%;

	border-radius: 5px;
	@media screen and (max-width: 769px) {
		display: flex;
		margin-top: 2%;
		margin-right: 0%;
		margin-top: 50px;
	}
	@media screen and (min-width: 1269px) {
		margin-right: 10%;
	}
}

.form-control {
	padding: 7px;
	border-radius: 6px;
	font-size: 16px;
	background: #fbfbfb;
	border: 2px solid transparent;
	height: 36px;
	box-shadow: 0 0 0 1px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 7%),
		0 1px 1.5px 0 rgb(0 0 0 / 5%);
	:focus {
		border: 2px solid #000;
		border-radius: 4px;
	}
	@media screen and (max-width: 769px) {
		width: 75vw;
	}
}

.form-input {
	width: 88%;
	@media screen and (max-width: 769px) {
		width: 102%;
	}
}

.form-hero {
	width: 600px;
	margin: auto;
	padding: 3rem 2rem;
	background: #ffffff;
	border-radius: 10px;
	padding: 2rem;
	@media screen and (max-width: 769px) {
		width: 100%;
	}

	#sendmessage {
		color: #007bff;
		border: 1px solid #007bff;
		display: none;
		text-align: center;
		padding: 15px;
		font-weight: 600;
		margin-bottom: 15px;
	}
	#errormessage {
		color: red;
		display: none;
		border: 1px solid red;
		text-align: center;
		padding: 15px;
		font-weight: 600;
		margin-bottom: 15px;
	}
	#sendmessage,
	#errormessage {
		&.show {
			display: block;
		}
	}
}
.form-group1 {
	display: flex;
	justify-content: space-evenly;
	@media screen and (max-width: 769px) {
		flex-direction: column;
		align-items: center;
	}
}
.hero-input-fields {
	padding-left: 5%;
	@media screen and (max-width: 769px) {
		padding-bottom: 4%;
	}
}
.form-group2 {
	display: flex;
	justify-content: space-evenly;
	padding-bottom: 16px;
	padding-top: 2%;
	@media screen and (max-width: 769px) {
		flex-direction: column;
		align-items: center;
	}
}
.form-group3 {
	padding-left: 9%;
	padding-bottom: 5%;
	@media screen and (max-width: 769px) {
		flex-direction: column;
		padding-left: 2%;
		align-items: center;
	}
}
.form-group4-parent-mobile {
	margin-left: 12px;
}
.form-group4-mobile {
	padding-left: 16px;
	padding-bottom: 16px;
	align-items: center;
	background-color: #352f44;
	border-radius: 8px;
	border-style: none;
	box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	font-family: "RM Neue", sans-serif;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	padding: 10px 21px;
	text-align: center;
	text-transform: none;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

.button-22:active {
	background-color: #006ae8;
}

.button-22:hover {
	background-color: #1c84ff;
}

.danny {
	margin: 0 auto;
	padding-bottom: 8px;
}
.contact-form-header-mobile {
	flex-direction: column;
	align-items: center;
	font-size: 4.7vw;
	width: fit-content;
	padding-left: 4.8%;
}
.disclaimer {
	padding-top: 8px;
	font-size: 12px;
}
