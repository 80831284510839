/* Check */
/* final check */
#services {
	padding-top: 75px; /* Adjust this value based on your navbar's height */
	margin-top: -20px; /* Negative margin to counteract the added padding */
}

#testimonials {
	padding-top: 90px; /* Adjust this value based on your navbar's height */
	margin-top: -20px; /* Negative margin to counteract the added padding */
}
