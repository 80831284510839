.footerContainer {
	background-color: var(
		--nav-bg
	); /* Black background for the entire footer */
	color: #dbd8e3; /* White text for readability */
	padding: 20px; /* Padding around the entire footer */
	box-shadow: 0 -2px 5px rgba(255, 255, 255, 0.1); /* Subtle white shadow for a lifted effect */
}

.footer {
	display: flex;
	flex-wrap: wrap;
	padding: 0px;
	justify-content: space-evenly;
	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
}

.grid-item1,
.grid-item2,
.grid-item3 {
}
.llc {
	letter-spacing: 1px;
}
.grid-item1 {
	width: 23%;
	@media screen and (max-width: 768px) {
		width: 100%;
	}
}

.grid-item1 h3,
.grid-item2 h3,
.grid-item3 h3 {
	color: #dbd8e3;
	margin-bottom: 10px;
}

.grid-item1 p,
.grid-item2 p,
.grid-item3 p {
	line-height: 1.6;
	margin-bottom: 8px;
	color: #dbd8e3;
}
.grid-item3 p {
	text-align: center;
	@media screen and (max-width: 768px) {
		text-align: left;
	}
}

.header {
	text-decoration: none;
	color: white;
	transition: color 0.3s ease;
}

.header:hover {
	color: #ccc;
}

.copyRight {
	text-align: center;
	padding-top: 10px;
	font-size: 0.9em;
	color: #aaa;
}

.companyName {
	font-weight: bold;
	color: #fff;
}
.footer-services {
	cursor: pointer;
}
