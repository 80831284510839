/* * {
	color: #dbd8e3;
} */
.contact {
	margin-bottom: 100px;
	position: relative;
	width: 100%;
	padding: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #dbd8e3;
}

.contact .title {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5em;
	padding-bottom: 10px;
}
.phone {
	color: #dbd8e3;
}

.contact .contact-Container {
	width: 75%;
	max-width: 1100px;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-auto-rows: minmax(150px, auto);
	grid-gap: 20px;
	padding: 10px;
}

.boxOne {
	background-color: var(--nav-bg);
	grid-column: 1/4;
	grid-row: 1/2;
	display: flex;
	flex-direction: column;
	padding: 5px 20px;
	color: var(--text-white);
}
.boxOne .reachUs {
	font-size: 1.5em;
	margin: 4px 10px;
	color: #dbd8e3;
}

.boxInner {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
	color: #dbd8e3;
}
.iconSpan {
	background-color: var(--text-white);
	height: 35px;
	width: 35px;
	border-radius: 50%;
	margin: 4px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.iconSpan img {
	height: 25px;
	width: 25px;
}

.boxTwo {
	background-color: rgb(211, 215, 212);
	grid-column: 1/4;
	grid-row: 2/3;
	position: relative;
	padding: 0;
	margin: 0;
}

.boxThree {
	background-color: var(--nav-bg);
	grid-column: 4/8;
	grid-row: 1/3;
	padding: 20px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.form {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 10px 20px 20px 20px;
}

.sendMsg {
	font-size: 1.5rem;
	text-align: center;
	padding: 20px 20px 0px 20px;
	font-weight: 400;
	margin-bottom: 20px;
}

.nestedBox {
	width: 100%;
	display: flex;
	gap: 20px;
	margin-bottom: 20px;
}

.nestedBox div {
	width: 100%;
}

.nestedBox div input {
	outline: none;
	border: 1px solid #ddd;
	width: 100%;
	padding: 12px;
	border-radius: 4px;
	box-sizing: border-box;
	margin: 5px 0px;
}

.hubspot-form-group3 {
	margin-bottom: 20px;
}

.hubspot-form-group3 select {
	width: 100%;

	border-radius: 4px;
	outline: none;
	border: 1px solid #ddd;
	box-sizing: border-box;
}

.sendBtn {
	padding: 12px 20px;
	width: 100%;
	border-radius: 8px;
	outline: none;
	border: none;
	cursor: pointer;
	color: #dbd8e3;
	background-color: #5c5470;
	font-size: 1.2em;
	margin-top: 10px;
}

@media screen and (max-width: 1200px) {
	.contact {
		margin-bottom: 50px;
	}
	.contact .contact-Container {
		width: 90%;
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		grid-auto-rows: minmax(150px, auto);
		grid-gap: 20px;
		padding: 10px;
	}
}

@media screen and (max-width: 920px) {
	.contact {
		margin-bottom: 40px;
	}
	.boxOne {
		background-color: var(--nav-bg);
		grid-column: 1/5;
		grid-row: 2/3;
		display: flex;
		flex-direction: column;
		padding: 5px 5px;
		color: var(--text-white);
	}
	.boxOne .reachUs {
		font-size: 1em;
		margin: 4px 2px;
	}

	.boxInner {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 5px;
	}
	.iconSpan {
		background-color: var(--text-white);
		height: 35px;
		width: 35px;
		border-radius: 50%;
		margin: 4px 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	.iconSpan img {
		height: 20px;
		width: 20px;
	}

	.boxTwo {
		grid-column: 5/8;
		grid-row: 2/3;
		padding: 0;
		margin: 0;
	}

	.boxThree {
		background-color: var(--nav-bg);
		grid-column: 1/8;
		grid-row: 1/2;
	}
	.title {
		color: #2a2438;
	}
	.form {
		display: flex;
		flex-direction: column;
		padding: 10px 20px 20px 20px;
	}

	.sendMsg {
		padding: 20px 20px 0px 20px;
	}
	.nestedBox {
		width: 100%;
		display: flex;
		gap: 8px;
	}
	.nestedBox div {
		width: 50%;
	}
	.nestedBox div input {
		outline: none;
		border: none;
		width: 100%;
		padding: 10px;
		margin: 5px 0px;
	}

	.textarea {
		resize: none;
		outline: none;
		border: none;
		padding: 8px;
	}
	.sendBtn {
		padding: 10px 8px;
		width: 100%;
		outline: none;
		border: none;
		margin-top: 10px;
		cursor: pointer;
		border-radius: 5px !important;
	}
	.contact {
		position: relative;
		width: 100%;
		padding: 0px 10px 30px 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.contact .title {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.2em;
		padding-bottom: 20px;
	}
}

@media screen and (max-width: 600px) {
	.contact {
		margin-bottom: 20px;
	}
	.boxOne {
		background-color: var(--nav-bg);
		grid-column: 1/8;
		grid-row: 2/3;
		display: flex;
		flex-direction: column;
		padding: 5px 5px;
		color: var(--text-white);
	}
	.boxTwo {
		grid-column: 1/8;
		grid-row: 3/4;
		padding: 0;
		margin: 0;
	}
	.contact {
		position: relative;
		width: 100%;
		padding: 10px 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

@media screen and (min-width: 2000px) {
	.contact {
		margin: 180px 0px;
	}
}
