@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap");

.service {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 20px 20px;
	flex-direction: row;
	@media screen and (max-width: 768px) {
		padding: 0px;
	}
}

.service-nested {
	@media screen and (min-width: 969px) {
		flex-direction: row;
	}
}

.serviceContainer {
	display: flex;
	flex-direction: row;

	@media screen and (max-width: 1269px) {
		flex-direction: column;
	}
}

.serviceContainer .expert {
	display: flex;
}

.ser-imgDiv {
	padding: 0;
}
.serviceImgDiv img {
	width: auto;
	height: 11.5vh;
}
.service-nested {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2px;
	max-width: 400px;

	padding-bottom: 12px;
	padding-left: 10px;
	padding-right: 10px;
	margin: 20px;
	border: 1px solid var(--text-gray);
	border-radius: 5px;

	@media screen and (max-width: 768px) {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
		max-width: 400px;

		margin: 0px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
}
.lines .serviceTitle {
	font-family: "Fjalla One", sans-serif;
	padding-bottom: 5px;
	padding-left: 5px;
	padding-right: 5px;
	font-size: 2.125vh;
	text-align: center;
	color: #2a2438;
	line-height: 120%;
}

.service-text {
	color: #2a2438;
	text-align: center;
	font-size: 1.7vh;
	line-height: 120%;
}

.hourImg {
	border-radius: 20px;
}
.lines {
	line-height: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

@media screen and (max-width: 1140px) {
	.lines .serviceTitle {
		padding: 5px;
		font-size: 20px;
	}

	.serviceContainer .hours {
		width: 100%;
	}
}

@media screen and (max-width: 800px) {
	.service {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 30px 50px;
	}
}

@media screen and (max-width: 600px) {
	.lines .serviceTitle {
		padding: 5px;
		font-size: 20px;
	}

	.serviceContainer .hours {
		width: 100%;
	}
	.service {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.service-nested {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
		max-width: 400px;
		padding: 12px;
	}
	.ser-imgDiv {
		width: 90px;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px 6px;
	}
	.lines p {
		text-align: center;
	}
}
