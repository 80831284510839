.hero {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 75vh;
	background: linear-gradient(-45deg, #6a1a8a, #5c5470, #352f44, #3d0043);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;

	@media screen and (min-width: 1269px) {
		flex-direction: row;
	}
	@media screen and (max-width: 1269px) {
		height: 107vh;
	}
	@media screen and (max-width: 769px) {
		margin-bottom: 12%;
		margin-top: 9%;
	}
	@media screen and (max-width: 469px) {
		margin-top: 14%;
	}
	@media screen and (max-width: 339px) {
		margin-top: 20.5%;
	}
}

.hero__content {
	text-align: center;
	width: 100%;
	padding-right: 5%;
	padding-left: 5%;
	@media screen and (max-width: 769px) {
		width: 75%;
	}
}

.hero-contact {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%; /* Full width for mobile screens */
	margin-top: 6%;
	box-shadow: none; /* Removed drop shadow */
	border-radius: 5px;

	@media screen and (min-width: 769px) and (max-width: 1269px) {
		width: 75%; /* Adjusted to match intro slider on tablet view */
		margin-right: auto;
		margin-left: auto;
	}
	@media screen and (min-width: 1269px) {
		width: 50%;
		margin-right: 10%;
	}
}

.hero__title,
.hero__subtitle {
	margin: 0;
	color: white;
}

.hero__title {
	font-size: 64px;
	font-weight: lighter;
	@media screen and (max-width: 1269px) {
		padding-top: 6%;
	}
	@media screen and (max-width: 769px) {
		font-size: 24px; /* Adjust for smaller screens */
		padding-bottom: 10px;
	}
	@media screen and (min-width: 1269px) {
		padding-bottom: 1rem;
	}
}

.hero__subtitle {
	font-size: 40px;
	font-weight: lighter;
	@media screen and (max-width: 1269px) {
		font-size: 20px; /* Adjust for smaller screens */
	}
	@media screen and (max-width: 769px) {
		font-size: 16px; /* Adjust for smaller screens */
	}
}

.hero__button-container {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	gap: 4rem;
	@media screen and (min-width: 1269px) {
		display: flex;
		align-items: flex-end;
		justify-content: space-around;
	}
}
.hero__button {
	display: inline-block;
	outline: none;
	border: none;
	cursor: pointer;
	background: #1d1b1b;
	color: #ffffff;
	border-radius: 8px;
	padding: 14px 24px 16px;
	font-size: 18px;
	font-weight: 700;
	line-height: 1;
	border: 3px solid white;
	margin-top: 5rem;
	font-family: "Fjalla One", sans-serif;

	transition: background-color 0.3s;
	@media screen and (max-width: 769px) {
		padding: 14px 24px 16px;
		width: 100%;
		margin-top: 4rem;
	}
}

.hero__button:hover {
	background-color: rgba(255, 255, 255, 0.2);
}

.hero__badge {
	width: auto;
	height: 8rem;
	margin-top: 1rem; /* Adjust the height to fit well with the button */
	@media screen and (max-width: 769px) {
		height: 5.688rem; /* Smaller size for mobile */
	}
	@media screen and (max-width: 1269px) {
		height: 6rem;
		margin: 0;
	}
}

@media screen and (max-width: 1269px) {
	.hero__title,
	.hero__subtitle {
		font-size: 20px;
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.form-control {
	padding: 7px;
	border-radius: 6px;
	font-size: 16px;
	background: #fbfbfb;
	border: 2px solid transparent;
	height: 36px;
	box-shadow: 0 0 0 1px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 7%),
		0 1px 1.5px 0 rgb(0 0 0 / 5%);
	:focus {
		border: 2px solid #000;
		border-radius: 4px;
	}
}

.form-input {
	width: 100%; /* Ensures input fields take up full width on mobile */
	@media screen and (max-width: 769px) {
		width: 100%; /* Full width on smaller screens */
	}
}

.form-hero {
	/* Ensures the form takes up the full width of its container */
	margin: auto;
	padding: 3rem 2rem;
	background: #ffffff;
	border-radius: 10px;
	padding: 2rem;
	margin-bottom: 5%;

	@media screen and (max-width: 1269px) {
		padding: 2rem 1rem; /* Reduce padding on smaller screens */
	}
}

.contact-form-header {
	font-size: 24px;
	padding-left: 36px;
	padding-bottom: 36px;
	@media screen and (max-width: 769px) {
		font-size: 16px;
		font-weight: bold;
		padding-left: 0; /* Align center for smaller screens */
		text-align: center;
	}
}

.disclaimer {
	padding-top: 8px;
	font-size: 12px;
}
