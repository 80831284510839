header {
	width: 100%;
	height: 70px;
	transition: all 0.9s ease-in;
	display: flex;
	padding: 0px 100px;
	justify-content: center;
	z-index: 999;
	background-color: #2a2438;
	position: -webkit-sticky; /* For Safari */
	position: sticky;
	top: 0;
}

header.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;
}

nav {
	width: 100%;
	max-width: 1550px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

nav .desktopMenu {
	display: flex;
	flex: 1 1 0%;
	justify-content: flex-end;
	margin: 0 20px;
	z-index: 100;
	height: 100%;
	align-items: center;
	list-style-type: none;
	color: #5c5470;
}

.desktopMenu li .active {
	color: white;
	padding-bottom: 21px;
	border-bottom: 5px solid white;
}
.hamburgertext {
	color: #dbd8e3;
	font-size: 20px;
}
.desktopMenu li a {
	text-decoration: none;
	font-size: 18px;
	color: lightgrey;
	font-family: sans-serif;
	margin: 0 16px;
	height: 70px;
	transition: all 0.4s ease-in-out;
}
.desktopMenu li a:hover {
	transform: scale(1.5);
	color: var(--text-gray);
}

.logo img {
	height: 40px;
	width: 50px;
	z-index: 500;
}
.logo {
	z-index: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	color: var(--text-gray);
	font-size: 25px;
	font-weight: 500;
	cursor: pointer;
}
.social {
	display: flex;
	margin-left: 5%;
}

.fa-envelopeP,
.fa-phoneP {
	display: flex;
	margin-left: 20px;
	color: white;
}

.fa-phoneP {
	@media screen and (max-width: 1160px) {
		/* display: none !important; */
		font-size: 10px;
	}
}

.logo-span {
	color: white;
	z-index: 500;
	text-transform: uppercase;
}
a {
	text-decoration: none;
}

.hamburger {
	display: none;
	position: relative;
	z-index: 500;
	user-select: none;
	appearance: none;
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
}
.hamburger .bar {
	display: block;
	width: 33px;
	height: 4px;
	margin-bottom: 5px;
	position: relative;
	background-color: #ffff;
	border-radius: 6px;
	z-index: 200;
	transform-origin: 0 0;
	transition: 0.5s;
}

.hamburger.active .bar:nth-child(2) {
	opacity: 0;
	transform: translate(15px);
}
.hamburger.active .bar:nth-child(1) {
	transform: translate(0px, -2px) rotate(45deg);
}
.hamburger.active .bar:nth-child(3) {
	transform: translate(-3px, 3px) rotate(-45deg);
}

/* sidebar */
.mobileMenu {
	display: none;
	list-style-type: none;
	position: fixed;
	top: 0;
	left: 100%;
	width: 100%;
	min-height: 100vh;
	display: flex;
	z-index: 200;
	background-color: #2a2438;
	padding-top: 140px;

	transition: all 0.4s ease-in-out;
}
.mobileMenu.active {
	left: 0;
}
.menuUl {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
}
.mobileMenu .menuUl li a {
	padding: 12px 16px;
	font-size: 20px;
	color: #dbd8e3;
}

/* media querries */
@media screen and (max-width: 1024px) {
	header {
		width: 100%;
		background-color: #352f44 !important;
		height: 70px;
		transition: all 0.9s ease-in;
		display: flex;
		padding: 0px 20px;
	}
	.logo-span {
		transition: all 0.9s ease-in;
		color: var(--text-white);
		z-index: 500;
		text-transform: uppercase;
	}

	.desktopMenu li .active {
		color: white;
	}
}

@media screen and (max-width: 769px) {
	nav .desktopMenu {
		display: none;
	}
	.hamburger {
		display: block;
	}
	.social {
		display: none;
	}
	.mobileMenu {
		display: block;
	}
	.logo-span {
		font-size: 20px;
	}
}

@media screen and (max-width: 260px) {
	.logo {
		display: none;
	}
}

p {
	margin: 0;
}

.service-nav {
	text-decoration: none;
	font-size: 18px;
	color: lightgrey;
	font-family: sans-serif;
	margin: 0 16px;
	cursor: pointer;
	transition: all 0.4s ease-in-out;
}
